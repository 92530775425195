.casemanager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px; /* Adjust as needed to create space at the top */
}

.casemanager-container h1 {
  text-align: center;
}

.data-table {
  width: 60%;
}

.rdt_Table {
}
.rdt_TableRow {
  min-height: 40px !important;
  font-size: 15px !important;
}
.rdt_TableCol {
  height: 38px !important;
}
.rdt_TableCol_Sortable {  
  height: 38px !important;
  font-size: 14px !important;
}
.rdt_TableCell {
  height: 38px !important;
}
.rdt_TableHeader {
  background-color: transparent;
  height: 38px !important;
}
.rdt_TableFooter {
}
.rdt_TableHead {
  
}
.rdt_TableHeadRow {
  min-height: 40px !important;
}
.rdt_TableBody {
}
.rdt_ExpanderRow {
}

.rdt_Pagination {
  font-weight: bolder !important;
  height: 38px !important;
}

.inquiry-button {
  height: 30px;
  width: 75px;
  color: #34b0bf;
  border: 1px solid #34b0bf;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

.incomplete-status {
  border: 1px solid red; /* Red border for incomplete status */
  color: red;
}

.search-input {
	height: 32px;
	width: 200px;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 0 32px 0 16px;
}

.search-input:hover {
	cursor: pointer;
}

.reset-button {
	border-radius: 0 5px 5px 0;
	height: 34px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
  background-color: #34b0bf;
  border: 1px solid #34b0bf;
}

.reset-button:hover {
  color: #fff;
  border: 1px solid #34b0bf;
  background-color: #34b0bf;
}


.load-button {
  color: #34b0bf;
  border: 1px solid #34b0bf;
  background-color: transparent;

}

.load-button:hover {
  color: #fff;
  border: 1px solid #34b0bf;
  background-color: #34b0bf;
}