html, body {
  height: 100%;
  margin: 0;
}

.home-container {
  display: flex;
  flex-direction: column; 
  align-items: center;     
  width: 100%;             
  min-height: 100vh;       /* Ensure the container covers at least the full viewport height */
  background-image: url('../../assets/landing/White_Gate_Filigree.png');
  background-size: cover;  /* Ensure the image covers the entire container */
  background-repeat: no-repeat; /* Prevent the background from repeating */
  background-position: bottom;  /* Position the image at the bottom of the container */
}

.logo-box {
  width: 50%;              /* Each box takes up 50% of the container's width */
  text-align: right;      /* Center content inside each box */
  margin: 0 auto;          /* Center the boxes within the container */
  padding-top: 30px;
  padding-bottom: 50px;
}

.home-box {
  position: relative;  /* Set home-box as the reference point for absolutely positioned children */
  width: 50%;          /* Ensure it takes 50% width as you requested earlier */
  text-align: center;  /* Center content inside home-box */
  margin: 0 auto;      /* Center the box within the container */
  padding-bottom: 30px;
}

.image3 {
  position: absolute;  /* Position this image relative to the home-box */
  bottom: 0;           /* Align to the bottom of home-box */
  right: 0;            /* Align to the right of home-box */
}

.image-logo {
  max-width: 600px;        /* Optional: Limit the maximum width */
}

.image {
  padding-top: 30px;
  width: 100%;
  object-fit: contain; 
  /* border-radius: 10px 10px 0px 0px; */
}
