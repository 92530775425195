.offer-detail-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 20px; /* Adjust as needed to create space at the top */
  max-width: 80%;
}

.offer-logo-section {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center horizontally */
  max-width: 800px;
}

.case-details {
  padding-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%; 
  border-bottom: 1px dashed #0c2441;
}

.offer-details-container {
  padding-bottom: 15px;
}

.case-details .subheader {
  text-align: left;
  font-size: 21px;
  color: #0c2441;
  font-weight: 500;
}

.offer-details .label {
  font-size: 18px;
  color: #0c2441;
  font-weight: 500;
}


.offer-details-report-header {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
  min-width: 640px;
  background-color: #34b0bf;
  color: #fff;
  font-weight: 600;
}

.offer-details {
  border: 1px solid #0c2441;
  padding: 15px;
}

@media print {
  .offer-detail-report-container {
    margin: 0 auto !important; /* Center horizontally */
    display: flex !important;
    justify-content: center !important;   /* Center horizontally for flex containers */
    width: 100% !important;              /* Ensure the container spans the page */
    box-sizing: border-box !important;   /* Include padding and border in dimensions */
    -webkit-print-color-adjust: exact; /* Ensures accurate color printing in WebKit browsers */
    print-color-adjust: exact; /* Ensures accurate color printing in other browsers */
  }
}
