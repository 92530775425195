.case-container h1 {
  text-align: center;
}

.case-container {
  padding-top: 20px;
}

.case-form {
  width: 60%;
  margin: auto;
  padding-bottom: 25px;
}

.form-section {
  margin-bottom: 10px;
}

.form-section h2 {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 36px;
  color: #0c2441;
}

.form-section h3 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 21px;
  color: #0c2441;
  border-bottom: 1px dashed #0c2441;
  padding: 3px;
}

.form-row {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.form-group-100 {
  display: flex;
  width: 100%;
}

.form-group-wide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.case-contact label {
  margin-top: 46px !important;
}

.contact-type {
  width: 150px;
  font-weight: 500;
}

.contact-type label {
  margin-top: 5px;
}


.form-group label {
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #f7f9fd;
}

textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 150px;
  background-color: #f7f9fd;
}

.radio-group {
  margin-left: 10px;
  display: flex;
  gap: 10px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.case-form button {
  display: block;
  width: 200px;
  height: 36px;
  margin: 20px auto;
  background-color: #34b0bf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.remove-button {
  align-self: flex-start;
  background-color: white;
  border: 1px solid #34b0bf;
  color: #34b0bf;
  margin: 0px auto;

}

.add-button {
  background-color: white;
  border: 1px solid #34b0bf;
  color: #34b0bf;
  margin: 0px auto;
  width: 100px;
}
