/* SendQueue.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px; /* Adjust as needed to create space at the top */
}

.data-table {
  width: 60%;
}

.send-queue-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff; /* White background for the table */
  margin-bottom: 50px;
}

.send-queue-table th, .send-queue-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.send-queue-table th {
  background-color: #34b0bf; /* Primary color for the table headers */
  color: #ffffff; /* White text color */
  font-weight: bold;
  text-transform: uppercase;
}

.send-queue-table tr:nth-child(even) {
  background-color: #f7f9fd; /* Slightly darker shade for alternating rows */
}

.send-queue-table td.centered {
  text-align: center;
}

.checkbox {
  transform: scale(1.2);
  cursor: pointer;
}

.carrier-column {
  min-width: 225px;
}

.status-column {
  min-width: 300px;
}

.send-queue-subheader {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300; /* Montserrat light */
  padding-top: 10px;
}

.subheader-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900; /* Montserrat Regular */
  font-size: 18px;
}

.exchange-image {
  width: 60%;
  text-align: center;
  /* border-radius: 10px 10px 0px 0px; */
}

.exchange-title-section {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center horizontally */
}

.exchange-title-section h2 {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 36px;
  color: #0c2441;
}


.shopped-button {
  height: 35px;
  color: #fff;
  border: 1px solid #34b0bf;
  background-color: #34b0bf;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
}


.shopping-table-header p {
  float: left;
}

.shopping-table-header button {
  margin-top: 6px;
  float: right
}