.navbar {
  background-color: #fff;
  padding: 1rem 2rem;
  border-bottom: 2px solid #34b0bf;
  height: 60px;
}

.user-nav {
  width: 50%;
}

.navbar a {
  color: #0c2441;
  margin-right: 1rem;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  color: #34b0bf;
}

.nav-item {
  font-size: medium;
  font-weight: 700;
  padding: 0px 20px;
}

.navbar-logo{
  width: 50%;
  font-size: 28px;
  text-decoration: none;
  text-align: center;
}

.div-style {
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 100%;
}
