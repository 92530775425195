.order-records-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.order-records-header {
  font-size: 24px;
  font-weight: bold;
  color: #1E2941; /* Match the color theme */
  margin-bottom: 20px;
  text-align: center; /* Center align the header */
}

.order-records-description {
  margin-top: 35px;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center; /* Ensure the description is centered */
}

.order-records-link {
  color: #0000EE; /* Standard blue color for links */
  text-decoration: underline;
  font-weight: normal; /* Remove any bold styling */
  font-size: 18px; /* Adjust size if necessary */
  text-align: center;
}

.order-records-link img {
  width: 12%;
  text-align: center;
  padding-top: 10px;
  /* border-radius: 10px 10px 0px 0px; */
}


.order-records-link:hover {
  color: #551A8B; /* Darker purple on hover, similar to visited links */
  text-decoration: underline;
}


.order-records-image {
  width: 30%;
  text-align: center;
  padding-top: 25px;
  /* border-radius: 10px 10px 0px 0px; */
  
}

.order-records-title-section {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center horizontally */
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 18px;
}

.order-records-jetstream {
  margin-top: 35px;
  font-size: 18px;
  margin-bottom: 30px;
  color: #003366;
}
