.invoice-backup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px; /* Adjust as needed to create space at the top */
}

.button-container {
  display: flex;
}

.button-group {
  padding-top: 45px;
  width:  32px;
}

.case-details {
  padding-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%; 
  border-bottom: 1px dashed #0c2441;
}

.offer-details-container {
  padding-bottom: 15px;
}

.case-details .subheader {
  text-align: left;
  font-size: 21px;
  color: #0c2441;
  font-weight: 500;
}

.offer-details .label {
  font-size: 18px;
  color: #0c2441;
  font-weight: 500;
}


.offer-details-header {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%; 
  background-color: #34b0bf;
  color: #fff;
  font-weight: 600;
}

.offer-details {
  border: 1px solid #0c2441;
  padding: 15px;
}


/* Wrapper styles */
.invoice-table-wrapper {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
}

/* Table styles */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th {
  padding: 10px;
  background-color: #34b0bf;
  color: #FFF;
  text-align: left;
  border: 1px solid #ccc;
}

.invoice-table tr:nth-child(even) {
  background-color: #F9F9F9;
}

.invoice-table tr:nth-child(odd) {
  background-color: #FFF;
}

.invoice-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.exchange-partner-header {
  display: none;
}

.pdf-title {
  display: none;
}

@media print {
  .invoice-backup-container {
    margin: 0 auto !important; /* Center horizontally */
    display: flex !important;
    justify-content: center !important;   /* Center horizontally for flex containers */
    width: 100% !important;              /* Ensure the container spans the page */
    box-sizing: border-box !important;   /* Include padding and border in dimensions */
  }

  .pdf-title {
    display:contents;
  }

  .page-title {
    display: none;
  }

  .exchange-partner-header {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex !important;
    flex-direction: column !important; /* Arrange children vertically */
    align-items: center !important; /* Center horizontally */
    width: 100% !important;
  }
}
