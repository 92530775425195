.exchange-form {
  width: 100%;
  margin: auto;
}

.exchange-form h3 {
  text-align: left;
  color: #212529;
  font-size: 1rem;
  font-weight: 500;
}

.exchange-form .dropzone {
  margin-top: 3px;
  margin-bottom: 5px;
  width: 100%;
  height: 100px;
}