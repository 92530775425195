.offer-quality-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px; /* Adjust as needed to create space at the top */
}

.offer-quality-wrapper {
  padding: 20px;
  max-width: 100%;
  margin: auto;
}

/* Table styles */
.offer-quality-table {
  width: 100%;
  border-collapse: collapse;
}

.offer-quality-table th {
  padding: 10px;
  background-color: #34b0bf;
  color: #FFF;
  text-align: left;
  border: 1px solid #ccc;
}

.offer-quality-table tr:nth-child(even) {
  background-color: #F9F9F9;
}

.offer-quality-table tr:nth-child(odd) {
  background-color: #FFF;
}

.offer-quality-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.oq-exchange-partner-header { 
  display: none;
}

.report-logo-section {
  display: none;
}

.offer-quality-filter{
  margin: auto;
  border: 1px solid #34b0bf;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.offer-quality-filter label {
  margin-top: 0px;
  padding-top: 0px;
}

.export-button {
  padding-top: 35px;
  width:  32px;
}

@media print {
  .offer-quality-wrapper {
    -webkit-print-color-adjust: exact; /* Ensures accurate color printing in WebKit browsers */
    print-color-adjust: exact; /* Ensures accurate color printing in other browsers */
  }

  @page {
    margin-top: 30px; /* Adds top margin to all pages */
  }

  .offer-quality-filter{
    display: none;
  }

  .oq-exchange-partner-header {
    display: flex !important;
    flex-direction: column !important; /* Arrange children vertically */
    align-items: center !important; /* Center horizontally */
    width: 100% !important;
  }
  
  .offer-logo-section {
    display: none;
  }

  .report-logo-section {
    padding-top: 10px;
    display: inline;
  }

  .report-logo-subsection {
    display: inline;
  }
}
