.casemanager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

.casemanager-container h1 {
  text-align: center;
}

.data-table {
  min-width: 1030px;
}

/* .rdt_Table {
}
.rdt_TableRow {
  min-height: 40px !important;
  font-size: 15px !important;
}
.rdt_TableCol {
  height: 38px !important;
}
.rdt_TableCol_Sortable {  
  height: 38px !important;
  font-size: 14px !important;
}
.rdt_TableCell {
  height: 38px !important;
}
.rdt_TableHeader {
  background-color: transparent;
  height: 38px !important;
}
.rdt_TableFooter {
}
.rdt_TableHead {
  
}
.rdt_TableHeadRow {
  min-height: 40px !important;
}
.rdt_TableBody {
}
.rdt_ExpanderRow {
}

.rdt_Pagination {
  font-weight: bolder !important;
  height: 38px !important;
} */

.inquiry-button {
  height: 30px;
  width: 75px;
  color: #34b0bf;
  border: 1px solid #34b0bf;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

.incomplete-status {
  border: 1px solid red; /* Red border for incomplete status */
  color: red;
}

.feedback-button {
  height: 30px;
  width: 95px;
  color: #34b0bf;
  border: 1px solid #34b0bf;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

.feedback-requested {
  border: 1px solid #0c2441;
  color: #0c2441;
}

.feedback-responded {
  border: 1px solid red;
  color: red;
}

.case-select {
  height: 30px;
  width: 95px;
  color: #0c2441;
  font-size: 14px;
  border: none;
  border-radius: 4px;
}