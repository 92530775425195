.search-container {
  margin: auto;
  border: 1px solid #34b0bf;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-container .form-group{
  padding-top: 0px;
}

.search-container label{
  margin-top: 0px;
  margin-bottom: 0px;
}

.search-button {
  padding-top: 24px;
  width: 76px;
}

