.register-container {
  justify-content: center;
  height: 78vh;
}

.register-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-box {
  max-width: 22rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem; /* equivalent to borderRadius: 1 */
  box-shadow: 0 4px 12px gray;
}

.typography-register-h2 {
  font-size: 2.2em;
  color: #000;
  margin-bottom: 8px; /* Assuming 1 spacing unit equals 8px */
}

.typography-register-links {
  font-size: 0.9rem;
  margin-bottom: 0;
  padding: 2px 0 15px;
}

.loading-button {
  height: 45px;
}

.loading-button:hover {
  transform: translateY(-2px);
}

.carrier-role {
  border-radius: 4px;
  border: 1px solid #ced4da;
  height: 46px;
  padding: 0 10px;
}