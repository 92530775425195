.invoice-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px; /* Adjust as needed to create space at the top */
}

.invoice-container {
  font-family: "Arial", sans-serif;
  color: #333;
  width: 675px;
  margin: auto;
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}

.invoice-container h5 {
  padding: 3px;
  background-color: #34b0bf;
  color: #FFF;
  text-align: left;
  border: 1px solid #ccc;
  font-size: 18px;
  margin-bottom: 0px;
}

.header, .bill-to-section, .final-section {
  display: flex;
  justify-content: space-between;
}

.header div, .bill-to-section div {
  flex: 1;
}

.bill-to-section, .invoice-items, .final-section {
  padding-top: 20px;
}

.header .from-section {
  margin-right: 20px;
  align-items: start;
}

.invoice-info, .invoice-totals {
  text-align: right;
}

.invoice-info p, .invoice-totals p {
  font-size: 14px;
  margin: 0px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th {
  background-color: #34b0bf;
  color: #fff;
  text-align: center;
  padding: 3px;
  border: 1px solid #ddd;
}

.invoice-table td {
  border: 1px solid #ddd;
  padding: 3px;
}

.center-text input {
  text-align: center;
}

.invoice-table .long {
  width: 100%;
  font-size: 14px;
}

.invoice-table .short {
  width: 120px;
}

.invoice-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.invoice-container .form-group {
  margin-bottom: 15px;
}

.invoice-container input {
  border: 0px;
  font-size: 14px;
}

.footer-notes {
  margin-top: 30px;
  font-size: 12px;
  color: #777;
  text-align: center;
}

.box {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}


.due-terms input, .invoice-info input, .invoice-totals input {
  border: none;
  font-size: 14px;
  width: 100px;
  text-align: center;
}

/* Table styles */
.due-table {
  text-align: center;
  border-collapse: collapse;
  float: right;
}

.due-table th {
  padding: 2px;
  background-color: #34b0bf;
  color: #FFF;
  border: 1px solid #ccc;
}

.due-table td {
  padding: 2px;
  border: 1px solid #ccc;
}

.comments-section {
  border: 1px solid black;
  font-size: 14px;
  text-align: center;
  width: 50%;
}

.invoice-totals{
  width: 200px;
  float: right;
}

.underline {
  border-bottom: 2px solid black;
}

.exchange-partner-header {
  display: none;
}

@media print {
  .exchange-partner-header {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex !important;
    flex-direction: column !important; /* Arrange children vertically */
    align-items: center !important; /* Center horizontally */
    width: 100% !important;
  }

  .subnavbar, .navbar, .carrier-filter, .page-title {
    display: none !important;
  }

  .container {
    margin: 50px !important;
  }

  .invoice-container {
    padding: 0px !important;
  }

  .invoice-table th, .due-table th, h5 {
    background-color: #34b0bf;
    color: #fff;
    -webkit-print-color-adjust: exact; /* Ensures accurate color printing in WebKit browsers */
    print-color-adjust: exact; /* Ensures accurate color printing in other browsers */
  }
}