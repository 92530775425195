.subnavbar {
  align-items: center;
  height: 25px;
  width: 100%;
  border-bottom: 1px solid #34b0bf;
  background-color: #34b0bf;
}

.subnav-item {
  height: 25px;
  padding: 0px 40px;
  padding-top: 1px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing:1px;
  font-family: 'Roboto', arial, sans-serif;
}

.sub-nav-link {
  text-decoration: none;
  vertical-align: middle;
  color: #f7f9fd;
}

.sub-nav-link:hover,
.sub-nav-link:focus,
.sub-nav-link:active {
  color: #0c2441;
}

.sub-nav-link-active {
  color: #0c2441;
}

.dropdown-toggle {
  padding-bottom: 3px;
}

.dropdown-menu {
  border: 1px solid lightgray;
  --bs-dropdown-link-active-bg: #f9f9f9;
  --bs-dropdown-link-active-color: #0c2441; 
  background-color: #f9f9f9;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-item {
  color: #333;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing:1px;
  font-family: 'Roboto', arial, sans-serif;
}

.dropdown-item:hover {
  background-color: lightgrey;
  color: #0c2441;
  border-left: 3px solid #34b0bf;
}
